.dropbtn {
  background-color: inherit;
  color: #000;
  font-size: 12px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}
.btn {
  border: 1px transparent !important;
}
.btn-light {
  color: #212529;
  background-color: #fff !important;
  border-color: #f8f9fa;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  // padding-inline: 10px;
  padding: 5px 5px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

.dropdown:hover .dropbtn {background-color: inherit;}