.collection-item {
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;
    position: relative;

    .image {
        background-size: cover;
        background-position: center;
        margin-bottom: 5px;
        opacity: 1;
    }

    &:hover {
        .image {
            opacity: 0.8;
        }
    }

    .collection-footer {
        width: 90%;
        height: 5%;
        display: flex;
        justify-content: flex-start;
        font-size: 18px;

        .name {
            width: 10%;
            margin-bottom: 15px;
            overflow: hidden;
        }

        .price {
            width: 100%;
        }
    }
}